export default {
  products: [],
  productNameToCreate: '',
  productDeletionPending: [],
  productCreationPending: false,
  locationId: null,
  costumerName: null,
  qtyCostumers: null,
  qtyCostumersOnService: null,
  costumerPhone: null,
  costumerIsPriority: false,
  checked: false,
  tabState: 1,
  serviceStatus: null,
  costumerIsInQueue: null,
  rating: null,
  newClientIdAfterFinished: null,
  ratingMessage: null,
  isNotified: false,
  locationName: null,
  actualPosition: null,
  ticketNumber: null
}
