<template>
  <div>
    <div class="hero-body">
      <div class="container">
        <div class="columns is-vcentered is-centered">
          <div class="column is-10 is-offset-1 landing-caption">
            <h1 class="title">Entre em contato</h1>
            <h2 class="subtitle">
              Estamos a diposição para esclarecer qualquer dúvida sobre o Lyne
            </h2>
            <iframe
              style="width: 100%; margin: auto"
              height="600px"
              src="https://share.hsforms.com/17wShlGJBRui0mFzWRzjFIQ188wk"
            >
            </iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  head() {
    return {
      title: {
        inner: 'Home'
      },
      meta: [
        {
          name: 'description',
          content: `${this.appTitle} home page`,
          id: 'desc'
        }
      ]
    }
  },
  computed: mapState('app', ['appTitle'])
}
</script>
<style>
.image-size {
  height: 120px !important;
  margin-bottom: 15px;
}
</style>
