export default {
  appTitle: 'Lyne',
  appShortTitle: 'lyne',
  networkOnLine: true,
  SWRegistrationForNewContent: null,
  showAddToHomeScreenModalForApple: false,
  refreshingApp: false,
  costumerCheckIn: false
  // checkUrl: false
}
