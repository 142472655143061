<template>
  <div>
    <div class="hero-body">
      <div class="container">
        <div class="columns is-vcentered">
          <div class="column is-5 is-offset-1 landing-caption">
            <h1 class="title is-1 is-bold is-spaced">
              Lyne é uma fila virtual segura e fácil de usar
            </h1>
            <h2 class="subtitle is-5 is-muted">
              Através de um código QR no estabelecimento, você entra na fila
              virtual e aguarda sua vez em local seguro e confortável. Quando
              sua vez se aproximar, nós te avisamos via Whatsapp para você se
              dirigir para atendimento.
            </h2>
            <div class="button-wrap">
              <a href="/login" class="button is-primary is-large">
                Comece agora
              </a>
            </div>
          </div>
          <div class="column is-5">
            <figure class="image ">
              <img
                alt="qr-code is-block"
                src="@/assets/img/social-distancing.svg"
              />
            </figure>
          </div>
        </div>
      </div>
    </div>
    <section class="hero ">
      <div class="hero-body">
        <div class="container has-text-centered">
          <h1 class="title ">
            Como funciona
          </h1>
          <h2 class="subtitle">
            Para estabelecimentos e para clientes
          </h2>
        </div>
      </div>
    </section>
    <section class="hero ">
      <div class="hero-body">
        <div class="container has-text-left">
          <h1 class="title ">
            Para estabelecimentos
          </h1>
          <h2 class="subtitle">
            Crie uma fila virtual e valorize a saúde e o tempo dos seus clientes
          </h2>
        </div>
      </div>
    </section>
    <section class="">
      <nav class="level ">
        <div class="level-item has-text-centered">
          <div>
            <p class="heading">Passo 1</p>

            <figure class="">
              <img
                alt="qr-code is-block image-size"
                src="@/assets/img/step1.svg"
                class="image-size"
              />
            </figure>
            <p class="heading">
              Faça seu cadstro na lyne <br />
              e crie sua primeira fila
            </p>
          </div>
        </div>
        <div class="level-item has-text-centered">
          <div>
            <p class="heading">Passo 2</p>

            <figure class="">
              <img
                alt="qr-code is-block "
                class="image-size"
                src="@/assets/img/step2.svg"
              />
            </figure>
            <p class="heading">
              Imprima o cartaz gerado pela lyne <br />
              e deixe disponível para os seus clientes acessarem a fila
            </p>
          </div>
        </div>
        <div class="level-item has-text-centered">
          <div>
            <p class="heading">Passo 3</p>

            <figure class="">
              <img
                alt="qr-code is-block "
                class="image-size"
                src="@/assets/img/step4.svg"
              />
            </figure>
            <p class="heading">
              Na área gerencial da Lyne <br />
              e administre sua fila, <br />
              notificando seus clientes da sua vez
            </p>
          </div>
        </div>
        <div class="level-item has-text-centered">
          <div>
            <p class="heading is-bold">Passo 4</p>

            <figure class="">
              <img
                alt="qr-code is-block "
                class="image-size"
                src="@/assets/img/step3.svg"
              />
            </figure>
            <p class="heading">
              Finalize os atendimentos dos clientes <br />
              e receba feedbacks
            </p>
          </div>
        </div>
      </nav>
    </section>

    <section class="hero ">
      <div class="hero-body">
        <div class="container has-text-left">
          <h1 class="title ">
            Para clientes
          </h1>
          <h2 class="subtitle">
            Muito conforto e segurança enquanto precisam realizar tarefas
            essenciais
          </h2>
        </div>
      </div>
    </section>

    <section class="" style="margin-top: 15px">
      <nav class="level ">
        <div class="level-item has-text-centered">
          <div>
            <p class="heading">Passo 1</p>

            <figure class="">
              <img
                alt="qr-code is-block image-size"
                src="@/assets/img/step1-cli.svg"
                class="image-size"
              />
            </figure>
            <p class="heading">
              Procure o QRCode da Lyne e <br />
              faça a leitura apontando <br />
              a câmera do celular
            </p>
          </div>
        </div>
        <div class="level-item has-text-centered">
          <div>
            <p class="heading">Passo 2</p>

            <figure class="">
              <img
                alt="qr-code is-block "
                class="image-size"
                src="@/assets/img/step2-cli.svg"
              />
            </figure>
            <p class="heading">
              Digite o seu nome <br />
              e Whatsapp para entrar na fila. <br />
              Você não precisar ter um cadastro na Lyne
            </p>
          </div>
        </div>
        <div class="level-item has-text-centered">
          <div>
            <p class="heading">Passo 3</p>

            <figure class="">
              <img
                alt="qr-code is-block "
                class="image-size"
                src="@/assets/img/step3-cli.svg"
              />
            </figure>
            <p class="heading">
              Acompanhe a distância a fila <br />
              pelo celular ou aguarde<br />
              ser notfificado pelo Whatsapp.
            </p>
          </div>
        </div>
        <div class="level-item has-text-centered">
          <div>
            <p class="heading is-bold">Passo 4</p>

            <figure class="">
              <img
                alt="qr-code is-block "
                class="image-size"
                src="@/assets/img/step5-cli.svg"
              />
            </figure>
            <p class="heading">
              Quando for notificado dirija-se <br />
              ao local de atendimento
            </p>
          </div>
        </div>
      </nav>
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  head() {
    return {
      title: {
        inner: 'Home'
      },
      meta: [
        {
          name: 'description',
          content: `${this.appTitle} home page`,
          id: 'desc'
        }
      ]
    }
  },
  computed: mapState('app', ['appTitle'])
}
</script>
<style>
.image-size {
  height: 120px !important;
  margin-bottom: 15px;
}
</style>
