import LocationsDB from "@/firebase/location-costumers-db";
import LocationsDB2 from "@/firebase/location-db";
import LocationsDBCostumerOnService from "@/firebase/location-costumers-on-service-db";
import firebase from "firebase/app";
import Axios from "axios";

import { isNil } from "lodash";

export default {
  /**
   * Fetch products of current loggedin user
   */
  getUserProducts: async ({ commit, state }) => {
    const userProductDb = new LocationsDB2(state.locationId);
    const products = await userProductDb.read(state.locationId);
    commit("setProducts", products);
    return products;
  },

  /**
   * Add a user to a location
   */
  addUserToLocation: async ({ commit, dispatch }, product) => {
    const userProductDb = new LocationsDB(product.locationId);
    commit("setLocationId", product.locationId);
    commit("setProductCreationPending", true);
    const a = product.phoneNumber;

    const costumer = await userProductDb
      .create(product, a)
      .then(snap => {
        localStorage.removeItem("whois");
        localStorage.setItem("whois", JSON.stringify(product));
        return snap;
      })
      .catch(error => console.log(error));

    const location = await firebase
      .firestore()
      .collection("locations")
      .doc(costumer.locationId)
      .get()
      .then(snap => {
        return snap.data();
      })
      .catch(error => console.log(error));
    costumer.ticketNumber = location.ticketNumber + 1;

    costumer.locationName = location.name;
    dispatch("notifyUserAfterAddToLocation", costumer);
  },

  notifyUserAfterAddToLocation: async ({ dispatch, commit }, product) => {
    const a = product.phoneNumber;

    console.log(product);

    const msg = `

    👩‍💻 Olá, sou Alyne, a assistente virtual da Lyne.
 

Você acessou a fila 🏢 *${
      product.locationName
    }*  aguarde com segurança e quando sua vez se aproximar, eu vou te avisar com uma mensagem neste chat. 
    
💡Por favor me adicione aos seus contato para que possa te mandar notificações sobre a sua fila. 

🔗Acompanhe como a fila está avançando aqui: https://lyne.in/costumers/checkin/${
      product.locationId
    }/${a}/null`;

    dispatch("notifyCostumer", [msg, product.phoneNumber]);
    commit("setServiceStatus", "waiting");
  },

  /**
   * Count and list the users on location
   */
  getCostumersQty: async ({ dispatch }, locationId) => {
    const userProductDb = new LocationsDB(locationId);
    const a = await userProductDb.readAll([]).catch(e => console.log(e));
    const b = { qty: a.length, location: locationId };
    return dispatch("setCostumersQtyOnDB", { b });
  },

  /**
   * Count and list the users on location being served
   */
  getCostumersQtyOnService: async ({ dispatch }, locationId) => {
    const userProductDb = new LocationsDBCostumerOnService(locationId);
    const a = await userProductDb.readAll([]).catch(e => console.log(e));
    const b = { qty: a.length, location: locationId };
    return dispatch("setCostumersQtyOnDBOnService", { b });
  },

  /**
   * Update the DB on the number of users
   */
  // eslint-disable-next-line no-unused-vars
  setCostumersQtyOnDB: async ({ commit }, payload) => {
    await firebase
      .firestore()
      .collection("locations")
      .doc(payload.b.location)
      .update({
        actualQty: payload.b.qty,
        updateTimestamp: await firebase.firestore.FieldValue.serverTimestamp()
      });

    return console.info("Qty updated");
  },

  /**
   * Update the DB on the number of costumer on service
   */
  // eslint-disable-next-line no-unused-vars
  setCostumersQtyOnDBOnService: async ({ commit }, payload) => {
    await firebase
      .firestore()
      .collection("locations")
      .doc(payload.b.location)
      .update({
        actualQty: payload.b.qty,
        updateTimestamp: await firebase.firestore.FieldValue.serverTimestamp()
      });

    return console.info("Qty updated");
  },

  /**
   * Check localstorage for saved data
   */
  checkLocalstorage: async ({ commit }) => {
    const savedData = JSON.parse(localStorage.getItem("whois"));
    if (!isNil(savedData)) {
      commit("setCostumerName", savedData.name);
      commit("setCostumerPhone", savedData.phoneNumber.replace(/\D+/g, ""));
      commit("setCostumerIsPriority", savedData.isPriority);
    }
  },

  /**
   * Update user status on Queue
   * @param newStatus
   * @param location
   * @param user
   */
  updateCostumerOnQueue: async ({ state, commit }, newStatus) => {
    let costumerId = state.costumerPhone;
    let loc = state.locationId;
    const serverTimestamp = await firebase.firestore.FieldValue.serverTimestamp();
    const decrease = firebase.firestore.FieldValue.increment(-1);
    const increase = firebase.firestore.FieldValue.increment(1);

    if (!costumerId) {
      const id = 2;
      costumerId = newStatus[id];
    }
    if (!loc) {
      const locId = 1;
      loc = newStatus[locId];
    }

    const db = firebase
      .firestore()
      .collection("locations")
      .doc(loc);

    if (newStatus[0] === "exited") {
      await db
        .collection("costumers")
        .doc(costumerId)
        .delete()
        .then(() => {
          commit("setServiceStatus", null);
          db.update({ actualQty: decrease });
        })
        .catch(e => {
          console.error(`Error on delete${e}`);
          commit("setServiceStatus", null);
        })
        .finally(() => {
          console.log("Deleted from Costumers and added to Exited");
        });
    } else if (newStatus[0] === "costumersOnService") {
      await db
        .collection("costumers")
        .doc(costumerId)
        .get()
        .then(snap => {
          const updateSnapData = snap.data();
          updateSnapData.startedServiceTimestamp = serverTimestamp;
          updateSnapData.serviceStatus = "onService";
          db.collection("costumersOnService")
            .doc(costumerId)
            .set(updateSnapData)
            .then(() => {
              db.update({ actualQtyOnService: increase });
              db.collection("costumers")
                .doc(costumerId)
                .delete()
                .then(() => {
                  commit("setServiceStatus", "onService");
                  db.update({ actualQty: decrease });
                });
            })
            .catch(e => {
              console.error(e);
            })
            .finally(() => {
              console.log("Costumer beign served");
            });
        });
    } else if (newStatus[0] === "costumersServed") {
      await db
        .collection("costumersOnService")
        .doc(costumerId)
        .get()
        .then(snap => {
          const updateSnapData = snap.data();
          updateSnapData.serviceStatus = "served";
          updateSnapData.finishedServiceTimestamp = firebase.firestore.FieldValue.serverTimestamp();
          const a = updateSnapData.startedServiceTimestamp.seconds;
          const b = updateSnapData.joinedQueueTimestamp.seconds;
          const c = a - b;

          updateSnapData.timeFromStartToFinish = c;

          return new Promise((resolve, reject) => {
            db.collection("costumersServed")
              .add(updateSnapData)
              .then(snapServed => {
                resolve(snapServed);
                db.update({ actualQtyServed: increase });
                db.collection("costumersOnService")
                  .doc(costumerId)
                  .delete()
                  .then(() => db.update({ actualQtyOnService: decrease }));
                commit("setServiceStatus", "served");
                commit("setNewClientIdAfterFinished", snapServed.id);

                console.log("Costumer served!");
              })
              .catch(e => {
                reject(console.error(e));
              });
          });
        });
    } // Else end

    return console.info("Queue updated");
  },

  /**
   * Check if costumer belongs to any queue on the location
   */

  checkIfUserIsActiveOnQueue({ commit }, [locationId, phoneNumber]) {
    let a = false;
    let b = false;
    let c = false;

    const db = firebase
      .firestore()
      .collection("locations")
      .doc(locationId);
    const dbOnService = db.collection("costumersOnService").doc(phoneNumber);
    const dbServed = db.collection("costumersServed").doc(phoneNumber);
    const dbQueue = db.collection("costumers").doc(phoneNumber);

    dbQueue.get().then(snap => {
      if (snap.exists) {
        a = true;
        commit("setCostumerName", snap.data().name);
        commit("setServiceStatus", "waiting");
      }
    });

    dbOnService.get().then(snapOnService => {
      console.log(snapOnService);
      if (snapOnService.exists) {
        b = true;
        commit("setCostumerName", snapOnService.data().name);
        commit("setServiceStatus", "onService");
      }
    });

    dbServed.get().then(snapServed => {
      if (snapServed.exists) {
        c = true;
        commit("setCostumerName", snapServed.data().name);
        commit("setCostumerPhone", snapServed.data().phoneNumber);
        commit("setServiceStatus", "served");
        commit("setTabState", 2);
      }
    });

    if (!a && !b && !c) {
      commit("setServiceStatus", null);
    }
  },

  /**
   * Data treatment befora adding a customer to the queue
   */
  triggerAddProductAction: async ({ rootState, dispatch, state }) => {
    let product = {};

    const serverTimestamp = await firebase.firestore.FieldValue.serverTimestamp();
    if (isNil(rootState.authentication.user)) {
      product = {
        userId: null,
        locationId: state.locationId,
        name: state.costumerName,
        phoneNumber: state.costumerPhone.replace(/\D+/g, ""),
        isPriority: state.costumerIsPriority,
        joinedQueueTimestamp: serverTimestamp,
        isNotified: false
      };
    } else {
      product = {
        userId: rootState.authentication.user.uid,
        name: state.costumerName,
        locationId: state.locationId,
        phoneNumber: state.costumerPhone.replace(/\D+/g, ""),
        isPriority: state.costumerIsPriority,
        joinedQueueTimestamp: serverTimestamp,
        isNotified: false
      };
    }

    dispatch("addUserToLocation", product);
  },

  /**
   * Notify costumer
   */

  // eslint-disable-next-line no-empty-pattern
  notifyCostumer: ({}, payload) => {
    let phone = payload[1];
    const message = payload[0];

    phone = `55${phone.replace(/\D+/g, "")}`;
    phone = phone.slice(0, 4) + phone.slice(5);

    const settings = {
      async: true,
      crossDomain: true,
      url: "https://meuatendimento-api.mandeumzap.com.br/v1/messages",
      method: "POST",
      headers: {
        Authorization: "Bearer 46b0aba9498ebc5a8071447abaff3eb964d6e131"
      },
      data: {
        serviceId: "473fcd53-816f-4659-8ffe-0fba8d6a364b",
        text: message,
        number: phone
      }
    };

    return new Promise((resolve, reject) => {
      Axios.post(settings.url, settings.data, {
        headers: {
          Authorization: "Bearer 46b0aba9498ebc5a8071447abaff3eb964d6e131"
        }
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error.response.data.errors);
        });
    });
  },

  /**
   * Delete a user product from its id
   */
  deleteUserProduct: async ({ rootState, commit, getters }, productId) => {
    if (getters.isProductDeletionPending(productId)) return;

    const userProductsDb = new LocationsDB(rootState.authentication.user.uid);

    commit("addProductDeletionPending", productId);
    await userProductsDb.delete(productId);
    commit("removeProductById", productId);
    commit("removeProductDeletionPending", productId);
  },
  /**
   * Set costummer rating
   * @param rating
   */
  setCostumerRating: async ({ state }, [payload]) => {
    const currentSelectedRating = payload[0];
    const message = payload[1];
    // const location = payload[2]
    firebase
      .firestore()
      .collection("locations")
      .doc(state.locationId)
      .collection("costumersServed")
      .doc(state.newClientIdAfterFinished)
      .update({ rating: currentSelectedRating, message });
  }
};
