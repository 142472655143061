import state from './open.state'
import mutations from './open.mutations'
import actions from './open.actions'
import getters from './open.getters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
