import state from './costumers.state'
import mutations from './costumers.mutations'
import actions from './costumers.actions'
import getters from './costumers.getters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
