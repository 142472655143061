import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firebase-analytics";
import "firebase/firebase-performance";

// The configuration below is not sensitive data. You can serenely add your config here
const config = {
  apiKey: "AIzaSyBiWsAPJrCpR_gr4zxcqt2U200UpP9Nr30",
  authDomain: "lyne.in",
  databaseURL: "https://lyne-76067.firebaseio.com",
  projectId: "lyne-76067",
  storageBucket: "lyne-76067.appspot.com",
  messagingSenderId: "500661624817",
  appId: "1:500661624817:web:259775f5df3488680eee50",
  measurementId: "G-FRCGJVXD04"
};

firebase.initializeApp(config);
firebase.analytics();
firebase.performance();
