import state from './tour.state'
import actions from './tour.actions'
import mutations from './tour.mutations'

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
