import router from '@/router'
import { isNil } from 'lodash'
import { createNewUserFromFirebaseAuthUser } from '@/misc/helpers'
import UsersDB from '@/firebase/users-db'
import firebase from 'firebase/app'
import 'firebase/firestore'

export default {
  /**
   * Callback fired when user login
   */
  login: async ({ commit }, firebaseAuthUser) => {
    const userFromFirebase = await new UsersDB().read(firebaseAuthUser.uid)

    const user = isNil(userFromFirebase)
      ? await createNewUserFromFirebaseAuthUser(firebaseAuthUser)
      : userFromFirebase

    commit('setUser', user)
    // dispatch('locations/getUserProducts', null, { root: true })
  },

  /**
   * Callback fired when user logout
   */
  logout: ({ commit }) => {
    commit('setUser', null)

    commit('products/setProducts', null, { root: true })

    // window.localStorage.clear()

    const isCheckin = router.currentRoute.path.includes('/costumers/checkin/')

    const currentRouter = router.app.$route

    if (
      !(currentRouter.meta && currentRouter.meta.authNotRequired) &&
      !isCheckin
    ) {
      // router.push('/login')
    }
  },

  addUserDatabase: ({ state }) => {
    firebase
      .firestore()
      .collection('users')
      .doc()
      .set(state.user)
  }
}
