import Vue from "vue";
import Router from "vue-router";
import Head from "vue-head";
import Home from "@/views/Home";
import Contact from "@/views/Contact";
import PrivacePolicy from "@/views/PrivacePolicy";
import CheckLogin from "@/views/CheckLogin";
import { isNil } from "lodash";
import store from "@/store";

Vue.use(Router);

/* If you don't know about VueHead, please refer to https://github.com/ktquez/vue-head */

Vue.use(Head, {
  complement: process.env.VUE_APP_TITLE
});

/* If you don't know about VueRouter, please refer to https://router.vuejs.org/ */

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/home",
      name: "home",
      component: Home,
      meta: {
        authNotRequired: true
      }
    },
    {
      path: "/privacy",
      name: "privacy",
      component: PrivacePolicy,
      meta: {
        authNotRequired: true
      }
    },
    {
      path: "/contact",
      name: "contact",
      component: Contact,
      meta: {
        authNotRequired: true
      }
    },
    {
      path: "/check-login",
      name: "check-login",
      component: CheckLogin,
      meta: {
        authNotRequired: true
      }
    },
    {
      path: "/login",
      name: "login",
      component: () =>
        import(/* webpackChunkName: "login" */ "@/views/Login.vue"),
      meta: {
        authNotRequired: true
      }
    },
    {
      path: "/register",
      name: "register",
      component: () =>
        import(/* webpackChunkName: "register" */ "@/views/Register.vue"),
      meta: {
        authNotRequired: true
      }
    },
    {
      path: "/products",
      name: "products",
      component: () =>
        import(/* webpackChunkName: "client-chunk-products" */ "@/views/Products.vue")
    },
    {
      path: "/products/:id",
      name: "product",
      props: true,
      component: () =>
        import(/* webpackChunkName: "client-chunk-product-details" */ "@/views/Product.vue")
    },
    {
      path: "/locations",
      name: "locations",
      component: () =>
        import(/* webpackChunkName: "client-chunk-locations" */ "@/views/Locations.vue")
    },
    {
      path: "/locations/:id",
      name: "location",
      props: true,
      component: () =>
        import(/* webpackChunkName: "client-chunk-location-details" */ "@/views/Location.vue")
    },
    {
      path: "/costumers/checkin/:id/:phone/:locationName",
      name: "costumers",
      props: true,
      component: () =>
        import(/* webpackChunkName: "client-chunk-checkin-details" */ "@/views/CostumerCheckIn.vue"),
      meta: {
        authNotRequired: true,
        hideMenu: true
      }
    },
    {
      path: "/open/checkin/:id/:locationName",
      name: "open",
      props: true,
      component: () =>
        import(/* webpackChunkName: "client-chunk-open-details" */ "@/views/PublicCheckIn.vue"),
      meta: {
        authNotRequired: false
      }
    },
    {
      path: "/utils/key/:locationId",
      name: "utils",
      props: true,
      component: () =>
        import(/* webpackChunkName: "client-chunk-utils" */ "@/views/PeopleKeyboard.vue"),
      meta: {
        authNotRequired: true
      }
    },
    { path: "*", redirect: "/home" }
  ]
});

/**
 * Handle user redirections
 */
// eslint-disable-next-line consistent-return
router.beforeEach((to, from, next) => {
  const isCheckin = to.path.includes("/costumers/checkin/");
  if (isCheckin) {
    next();
  } else {
    if (
      !(to.meta && to.meta.authNotRequired) &&
      isNil(store.state.authentication.user)
    ) {
      const path =
        store.state.authentication.user === null ? "/login" : "/check-login";
      return next(`${path}?redirectUrl=${to.path}`);
    }
    next();
  }
});

export default router;
