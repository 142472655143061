export default {
  /* Product input name */
  setQueue: (state, queue) => (state.queue = queue),

  /* Product input name */
  setQueueOnService: (state, queueOnService) =>
    (state.queueOnService = queueOnService),

  /* Data input name */
  setLocationId: (state, locationId) => (state.locationId = locationId),

  /* Set type of queu to show */
  setSelectedQueue: (state, selectedQueue) =>
    (state.selectedQueue = selectedQueue),

  /* Data input name */
  setData: (state, data) => (state.data = data)

  // /* Set queue location */
  // setLocationId: (state, value) => (state.locationId = value)
}
