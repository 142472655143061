export default {
  /* Product input name */
  setProductNameToCreate: (state, productNameToCreate) =>
    (state.productNameToCreate = productNameToCreate),

  /* Product input name */
  setMaximumCapacity: (state, locationMaxQty) =>
    (state.locationMaxQty = locationMaxQty),

  /* Product input name */
  setKindOfLocation: (state, kindOfLocation) =>
    (state.kindOfLocation = kindOfLocation),

  /* Product input name */
  setActualQty: (state, actualQty) => (state.actualQty = actualQty),

  /* Set costumer rating */
  setRatingSent: (state, value) => (state.ratingSent = value),

  /* Set costumer rating */
  setFootFallHistory: (state, value) => (state.footFallHistory = value),

  /* Set costumer rating */
  setParams: (state, value) => (state.params = value),

  /* Products */
  setProducts: (state, products) => (state.products = products),

  /* Products */
  updateProducts: (state, products) => (state.products = products),

  /* Products */
  setUpdatingParams: (state, updatingParams) =>
    (state.updatingParams = updatingParams),

  removeProductById: (state, productId) => {
    const index = state.products.findIndex(product => product.id === productId)
    state.products.splice(index, 1)
  },

  /* Products deletion */
  addProductDeletionPending: (state, productId) =>
    state.productDeletionPending.push(productId),
  removeProductDeletionPending: (state, productId) => {
    const index = state.products.findIndex(product => product.id === productId)
    state.productDeletionPending.splice(index, 1)
  },

  /* Product creation */
  setProductCreationPending: (state, value) =>
    (state.productCreationPending = value)
}
