import { isNil } from "lodash";

export default {
  /**
   * Closes "add to home screen" modal for apple
   */
  closeAddToHomeScreenModalForApple: async ({ commit }) => {
    localStorage.setItem("addToHomeIosPromptLastDate", Date.now());
    commit("setShowAddToHomeScreenModalForApple", false);
  },

  /**
   * Trigger service worker skipWating so the new service worker can take over.
   * This will also trigger a window refresh (see /src/misc/register-service-worker.js)
   */
  serviceWorkerSkipWaiting({ state, commit }) {
    if (isNil(state.SWRegistrationForNewContent)) return;

    commit("setRefreshingApp", false);
    state.SWRegistrationForNewContent.waiting.postMessage("skipWaiting");
  },
  verifyIfIsClientCheckInPage({ commit }) {
    console.log("called");
    let path = window.location.pathname;
    if (/costumers\/checkin/.test(path)) {
      console.log(true);
      commit("app/setCostumerCheckIn", true);
    }
    commit("app/setCostumerCheckIn", false);
  }
};
