export default {
  /* Product input name */
  setProductNameToCreate: (state, productNameToCreate) =>
    (state.productNameToCreate = productNameToCreate),

  /* Product input name */
  setLocationId: (state, value) => (state.locationId = value),

  /* Product input name */
  setLocationName: (state, value) => (state.locationName = value),

  /* Set costumers name */
  setCostumerName: (state, value) => (state.costumerName = value),

  /* Set costumer phone */
  setCostumerPhone: (state, value) => (state.costumerPhone = value),

  /* Product input name */
  setTicketNumber: (state, value) => (state.ticketNumber = value),

  /* Set costumer rating */
  setRating: (state, value) => (state.rating = value),

  /* Set costumer rating */
  setRatingMessage: (state, value) => (state.message = value),

  /* Set costumer rating */
  setIsNotified: (state, value) => (state.isNofied = value),

  /* Product input name */
  setMaximumCapacity: (state, locationMaxQty) =>
    (state.locationMaxQty = locationMaxQty),

  /* Product input name */
  setQtyCostumers: (state, costumers) => (state.qtyCostumers = costumers),

  /* Product input name */
  setQtyCostumersOnService: (state, costumers) =>
    (state.qtyCostumersOnService = costumers),

  /* Set costumer is a priority */
  setCostumerIsPriority: (state, costumerIsPriority) =>
    (state.costumerIsPriority = costumerIsPriority),

  /* Set costumer is a priority */
  setCostumerIsInQueue: (state, costumerIsInQueue) =>
    (state.costumerIsInQueue = costumerIsInQueue),

  /* Set costumer is a priority */
  setTabState: (state, tabState) => (state.tabState = tabState),

  /* Set service status */
  setServiceStatus: (state, serviceStatus) =>
    (state.serviceStatus = serviceStatus),

  /* Set if client is a priotiry */
  setChecked: (state, checked) => (state.checked = checked),

  /* Set if client is a priotiry */
  setNewClientIdAfterFinished: (state, newClientIdAfterFinished) =>
    (state.newClientIdAfterFinished = newClientIdAfterFinished),

  /* Set if client actual position in waiting list */
  setActualPosition: (state, actualPosition) =>
    (state.actualPosition = actualPosition),

  /* Products */
  setProducts: (state, products) => (state.products = products),
  addProduct: (state, product) => state.products.push(product),
  removeProductById: (state, productId) => {
    const index = state.products.findIndex(product => product.id === productId)
    state.products.splice(index, 1)
  },

  /* Products deletion */
  addProductDeletionPending: (state, productId) =>
    state.productDeletionPending.push(productId),
  removeProductDeletionPending: (state, productId) => {
    const index = state.products.findIndex(product => product.id === productId)
    state.productDeletionPending.splice(index, 1)
  },

  /* Product creation */
  setProductCreationPending: (state, value) =>
    (state.productCreationPending = value)
}
