export default {
  products: null,
  productNameToCreate: '',
  productDeletionPending: [],
  productCreationPending: false,
  locationQrCode: null,
  locationMaxQty: null,
  kindOfLocation: 'Escolha',
  actualQty: null,
  ratingSent: false,
  params: null,
  updatingParams: null,
  showHistory: false,
  footFallHistory: null,
  productId: null
}
