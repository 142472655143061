export default {
  /* Product input name */
  setLocationId: (state, locationId) => (state.locationId = locationId),

  /* Location  name */
  setLocationName: (state, locationName) => (state.locationName = locationName),

  /* Product input name */
  setProducts: (state, products) => (state.products = products),

  /* Product input name */
  setQtyCostumers: (state, costumers) => (state.qtyCostumers = costumers),

  /* Products deletion */
  addProductDeletionPending: (state, productId) =>
    state.productDeletionPending.push(productId),
  removeProductDeletionPending: (state, productId) => {
    const index = state.products.findIndex(product => product.id === productId)
    state.productDeletionPending.splice(index, 1)
  },

  /* Product creation */
  setProductCreationPending: (state, value) =>
    (state.productCreationPending = value)
}
