import Vue from 'vue'
import Vuex from 'vuex'
import Buefy from 'buefy'
import VueClipboard from 'vue-clipboard2'
import createPersistedState from 'vuex-persistedstate'
import authentication from './authentication'

import app from './app'
import products from './products'
import locations from './locations'
import costumers from './costumers'
import open from './open'
import queue from './queue'
import misc from './misc'
import tour from './tour'

Vue.use(Vuex)
Vue.use(Buefy)
Vue.use(VueClipboard)

/* If you don't know about Vuex, please refer to https://vuex.vuejs.org/ */

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    authentication,
    app,
    products,
    locations,
    costumers,
    open,
    queue,
    misc,
    tour
  },
  plugins: [createPersistedState()]
})
