import LocationsDB from '@/firebase/location-db'

export default {
  /**
   * Fetch products of current loggedin user
   */
  getLocationQrCode: async ({ commit, state }) => {
    const userProductDb = new LocationsDB(state.locationId)
    const products = await userProductDb.read(state.locationId)
    commit('setProducts', products)
    return products
  }
}
