<template>
  <div id="app">
    <nav-bar></nav-bar>
    <div class="container is-fluid">
      <div class="columns">
        <div class="column is-full">
          <router-view style="margin-top: 15px" />
        </div>
      </div>
    </div>

    <footer class="footer">
      <div class="content has-text-centered">
        <p>
          <strong>Lyne</strong> é uma tecnologia da startup
          <a href="https://meuatendimento.com.br">Meu Atendimento</a>
          disponibilizada gratuitamente no esforço contra o Covid-19.<br />
          Siga-nos no instagram
          <a href="https://www.instagram.com/lyne.in/"
            ><i class="fab fa-instagram"></i> @lyne.in</a
          >
        </p>
      </div>
    </footer>
  </div>
</template>
<script>
import NavBar from "@/components/NavBar";

import { mapState, mapActions, mapGetters } from "vuex";

export default {
  components: { NavBar },
  computed: {
    ...mapGetters("app", ["newContentAvailable"]),
    ...mapState("app", ["showAddToHomeScreenModalForApple", "refreshingApp"])
  },
  methods: mapActions("app", [
    "closeAddToHomeScreenModalForApple",
    "serviceWorkerSkipWaiting"
  ])
};
</script>

<style lang="scss">
// Set your colors
$danger: #f44336;
$info: #2082de;

$scheme-main: #fafaf9;
@import "~bulma/sass/utilities/_all";

// Links
$link: $primary;
$link-invert: $primary-invert;
$link-focus-border: $primary;

// Import Bulma and Buefy styles
@import "~bulma";
@import "~buefy/src/scss/buefy";
</style>
