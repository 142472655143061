import LocationsDB from "@/firebase/location-db";
import firebase from "firebase/app";
import Axios from "axios";

export default {
  /**
   * Fetch products of current loggedin user
   */
  getUserProducts: async ({ rootState, commit }) => {
    const userId = rootState.authentication.user.uid;

    await firebase
      .firestore()
      .collection("locations")
      .where("owner", "==", userId)
      .where("active", "==", true)
      .get()
      .then(snap => {
        if (snap.size > 0) {
          const locationsTemp = [];
          for (let i = 0; i < snap.size; i += 1) {
            const data = snap.docs[i].data();
            const { id } = snap.docs[i];
            if (snap.docs[i].data !== false) {
              locationsTemp.push({ ...data, id });
            }
          }
          console.log(locationsTemp);
          commit("setProducts", locationsTemp);
        }
        return null;
      })
      .catch(err => {
        console.log("Error loading locations: ", err);
      });
  },

  /**
   * Get AVG rating
   */
  getAvgRating: async ({ commit }, payload) => {
    const rating = payload[1][0];
    console.log(rating);
    const incrementRating = firebase.firestore.FieldValue.increment(rating);
    const incrementSurveysTaken = firebase.firestore.FieldValue.increment(1);
    firebase
      .firestore()
      .collection("locations")
      .doc(payload[0])
      .update({
        ratingSum: incrementRating,
        surveysTaken: incrementSurveysTaken
      })
      .then(() => {
        commit("setRatingSent", true);
      });
  },

  /**
   * Create a product for current loggedin user
   */
  createUserProduct: async ({ commit, rootState, dispatch }, product) => {
    const userProductDb = new LocationsDB(rootState.authentication.user.uid);

    commit("setProductCreationPending", true);
    await userProductDb.create(product).then(response => {
      dispatch("updateUserProduct", response);
    });
  },

  /**
   * Create a product for current loggedin user
   */
  updateUserProduct: async ({ commit, dispatch }, product) => {
    const userProductDb = new LocationsDB(product.id);
    let url = null;
    const productId = product.id.trim().replace("}", "");
    // Create QrCode
    await Axios.get(
      `https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${
        window.location.origin
      }/costumers/checkin/${productId}/null/${product.name}`
    ).then(response => {
      url = response.config.url;
      commit("setProductCreationPending", false);
      product.qrCodeUrl = url;
    });
    // Update location with QrCode
    const createdProduct = await userProductDb
      .update(product)
      .then(commit("setProductCreationPending", false));
    commit("addProduct", createdProduct);
    dispatch("getUserProducts");
  },

  /**
   * Create a new location and generate the QRCode from API
   */
  triggerAddProductAction: ({ rootState, dispatch, state, commit }) => {
    if (state.productNameToCreate === "") return;
    const product = {
      name: state.productNameToCreate,
      maxQtd: state.locationMaxQty,
      owner: rootState.authentication.user.uid,
      kindOfLocation: state.kindOfLocation,
      params: { timeForAutoDecrement: 0, useAutoDecrement: false },
      ticketNumber: 0,
      active: true
    };

    commit("setProductNameToCreate", "");
    commit("setMaximumCapacity", "");
    dispatch("createUserProduct", product);
  },

  commitKindOfLocation: ({ commit }, payload) => {
    commit("setKindOfLocation", payload);
  },

  // eslint-disable-next-line no-unused-vars
  deleteLocation: async ({ commit }, productId) => {
    console.log(productId);
    firebase
      .firestore()
      .collection("locations")
      .doc(productId)
      .update({ active: false })
      .then(() => {
        console.log("Location deleted.");
      });
  },

  /**
   * Delete a user product from its id
   */
  deleteUserProduct: async ({ commit, getters }, productId) => {
    if (getters.isProductDeletionPending(productId)) return;

    commit("addProductDeletionPending", productId);

    firebase
      .firestore()
      .collection("locations")
      .doc(productId)
      .update({ active: false })
      .then(() => {
        console.log("Location deleted.");
      });
    commit("removeProductById", productId);
    commit("removeProductDeletionPending", productId);
  }
};
