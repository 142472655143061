export default {
  products: null,
  productNameToCreate: '',
  productDeletionPending: [],
  productCreationPending: false,
  locationId: null,
  storeName: null,
  qtyCostumers: null,
  qtyCostumersOnService: null,
  locationName: null
}
