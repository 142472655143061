import actions from './queue.actions'
import mutations from './queue.mutations'
import getters from './queue.getters'

export default {
  namespaced: true,
  actions,
  mutations,
  getters
}
