export default {
  /**
   * Check if a product has deletion pending
   */
  // getQueue: state => {
  //   return state.queue
  // },
  /**
   * Get product by id
   */
  // getQtyWaiting: state => {
  //   return state.costumers.filter(todo => todo.done)
  // }
}
