import Vue from "vue";

import VueTour from "vue-tour";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import "@/misc/register-service-worker";
import "@/misc/handle-network-status";
import "@/firebase/init";
import "@/firebase/authentication";
import "@/misc/handle-apple-install-prompt";
import "pwacompat";

require("vue-tour/dist/vue-tour.css");

Vue.config.productionTip = false;

Vue.use(VueTour);

const vm = () =>
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount("#app");

vm();

// eslint-disable-next-line no-console
console.info(`
🍱 This app was bootstrapped with bento-starter 🍱

👉 https://bento-starter.netlify.com/

`);
