import state from './locations.state'
import mutations from './locations.mutations'
import actions from './locations.actions'
import getters from './locations.getters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
