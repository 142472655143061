<template>
  <div>
    <div class="columns">
      <div class="column is-8 is-offset-2">
        <div class="container">
          <h1 class="title">Política de privacidade</h1>
          <p><strong>A. Introdução</strong></p>
          <ol>
            <li>
              A privacidade dos visitantes do nosso site é muito importante para
              nós, e estamos comprometidos em protegê-la. Esta política explica
              o que faremos com suas informações pessoais.
            </li>
            <li>
              Consentir com o uso de cookies de acordo com os termos desta
              política quando você acessa nosso site pela primeira vez nos
              permite usar cookies toda vez que você acessa nosso site.
            </li>
          </ol>
          <p>
            <strong>B. Créditos</strong><br />
            Este documento foi criado usando um modelo da SEQ Legal
            (seqlegal.com) modificado pelo Website Lyne (<a
              href="https://lyne.in"
              >https://lyne.in</a
            >)
          </p>
          <p><strong>C. Coleta de informações pessoais</strong></p>
          <p>
            Os seguintes tipos de informações pessoais podem ser coletados,
            armazenados e usados:
          </p>
          <ol>
            <li>
              informações sobre o seu computador, incluindo seu endereço IP,
              localização geográfica, tipo e versão do navegador e sistema
              operacional;
            </li>
            <li>
              informações sobre suas visitas e uso deste site, incluindo fonte
              de referência, duração da visita, visualizações de página e
              caminhos de navegação no site;
            </li>
            <li>
              informações, como seu endereço de e-mail, que você digita quando
              se registra em nosso site;
            </li>
            <li>
              informações que você digita ao criar um perfil em nosso site – por
              exemplo, seu nome, fotos de perfil, sexo, data de nascimento,
              status de relacionamento, interesses e hobbies, informações
              educacionais e de emprego;
            </li>
            <li>
              informações, como seu nome e endereço de e-mail, que você digita
              para configurar assinaturas de nossos e-mails e/ou newsletters;
            </li>
            <li>
              informações que você digita durante o uso dos serviços em nosso
              site;
            </li>
            <li>
              informações geradas ao usar nosso site, incluindo quando, com que
              frequência e em que circunstâncias você o utiliza;
            </li>
            <li>
              informações relacionadas a tudo o que você compra, serviços que
              usa ou transações que realiza através do nosso site, incluindo
              nome, endereço, número de telefone, endereço de e-mail e dados do
              cartão de crédito;
            </li>
            <li>
              informações que você publica em nosso site com a intenção de
              publicá-las na internet, incluindo seu nome de usuário, fotos de
              perfil e o conteúdo de suas publicações;
            </li>
            <li>
              informações contidas em quaisquer comunicações que você nos envia
              por e-mail ou através de nosso site, incluindo o conteúdo e os
              metadados da comunicação;
            </li>
            <li>qualquer outra informação pessoal que você nos enviar.</li>
          </ol>
          <p>
            Antes de nos divulgar informações pessoais de outra pessoa, você
            deve obter o consentimento dessa pessoa para a divulgação e o
            processamento dessas informações pessoais de acordo com esta
            política.
          </p>
          <p><strong>D. Uso de suas informações pessoais</strong></p>
          <p>
            As informações pessoais que nos são enviadas por meio de nosso site
            serão usadas para os fins especificados nesta política ou nas
            páginas relevantes do site. Podemos usar suas informações pessoais
            para o seguinte:
          </p>
          <ol>
            <li>administrar nosso site e nossos negócios;</li>
            <li>personalizar nosso site para você;</li>
            <li>possibilitar o uso dos serviços disponíveis em nosso site;</li>
            <li>enviar produtos adquiridos através do nosso site;</li>
            <li>prestar serviços adquiridos através do nosso site;</li>
            <li>
              enviar extratos, faturas e lembretes de pagamento, bem como
              coletar seus pagamentos;
            </li>
            <li>enviar comunicações comerciais que não sejam de marketing;</li>
            <li>
              enviar notificações por e-mail solicitadas especificamente por
              você;
            </li>
            <li>
              enviar nossa newsletter por e-mail, caso você a tenha solicitado
              (você pode nos informar a qualquer momento se não quiser mais
              receber a newsletter);
            </li>
            <li>
              enviar comunicações de marketing relacionadas aos nossos negócios
              ou aos negócios de terceiros cuidadosamente selecionados que
              acreditamos ser do seu interesse, por correio ou, onde você
              especificamente concordou com isso, por e-mail ou tecnologia
              semelhante (você pode nos informar a qualquer momento se não mais
              quiser mais receber comunicações de marketing);
            </li>
            <li>
              fornecer a terceiros informações estatísticas sobre nossos
              usuários (mas esses terceiros não poderão identificar nenhum
              usuário individual a partir dessas informações);
            </li>
            <li>
              lidar com perguntas e reclamações feitas por você ou sobre você em
              relação ao nosso site;
            </li>
            <li>manter nosso site seguro e evitar fraudes;</li>
            <li>
              verificar a conformidade com os termos e condições que regem o uso
              do nosso site (incluindo o monitoramento de mensagens privadas
              enviadas por meio do serviço de mensagens privadas do nosso site);
              e
            </li>
            <li>outros usos.</li>
          </ol>
          <p>
            Se você enviar informações pessoais para publicação em nosso site,
            publicaremos e usaremos essas informações de acordo com a licença
            que você nos concedeu.
          </p>
          <p>
            Suas configurações de privacidade podem ser usadas para limitar a
            publicação de suas informações em nosso site e ajustadas através do
            uso de controles de privacidade no site.
          </p>
          <p>
            Sem seu consentimento expresso, não forneceremos suas informações
            pessoais a terceiros para fins de marketing direto por parte deles
            ou de terceiros.
          </p>
          <p><strong>E. Divulgação de informações pessoais</strong></p>
          <p>
            Podemos divulgar suas informações pessoais a qualquer um de nossos
            funcionários, executivos, seguradoras, consultores profissionais,
            agentes, fornecedores ou subcontratados conforme razoavelmente
            necessário para os fins estabelecidos nesta política.
          </p>
          <p>
            Podemos divulgar suas informações pessoais a qualquer membro de
            nosso grupo de empresas (isso significa nossas subsidiárias, nossa
            holding e todas as suas subsidiárias) conforme razoavelmente
            necessário para os fins estabelecidos nesta política.
          </p>
          <p>Podemos divulgar suas informações pessoais:</p>
          <ol>
            <li>na medida em que somos obrigados a fazê-lo por lei;</li>
            <li>
              em relação a qualquer processo judicial em andamento ou potencial;
            </li>
            <li>
              para estabelecer, exercer ou defender nossos direitos legais
              (incluindo fornecer informações a terceiros para fins de prevenção
              de fraudes e redução do risco de crédito);
            </li>
            <li>
              ao comprador (ou comprador em potencial) de qualquer negócio ou
              ativo que estejamos vendendo (ou contemplando vender); e
            </li>
            <li>
              a qualquer pessoa que acreditemos razoavelmente que possa
              solicitar a um tribunal ou outra autoridade competente a
              divulgação dessas informações pessoais, quando, em nossa opinião
              razoável, for provável que tal tribunal ou autoridade ordene a
              divulgação dessas informações pessoais.
            </li>
          </ol>
          <p>
            Exceto conforme estabelecido nesta política, não forneceremos suas
            informações pessoais a terceiros.
          </p>
          <p><strong>F. Transferências internacionais de dados</strong></p>
          <ol>
            <li>
              As informações que coletamos podem ser armazenadas, processadas e
              transferidas entre qualquer um dos países em que operamos, a fim
              de nos permitir usar as informações de acordo com esta política.
            </li>
            <li>
              As informações que coletamos podem ser transferidas para os
              seguintes países que não possuem leis de proteção de dados
              equivalentes às vigentes no Espaço Econômico Europeu: Estados
              Unidos da América, Rússia, Japão, China e Índia.
            </li>
            <li>
              As informações pessoais que você publica em nosso site ou envia
              para publicação em nosso site podem estar disponíveis, através da
              internet, em todo o mundo. Não podemos impedir o uso ou uso
              indevido de tais informações por terceiros.
            </li>
            <li>
              Você concorda expressamente com as transferências de informações
              pessoais descritas nesta seção F.
            </li>
          </ol>
          <p><strong>G. Retenção de informações pessoais</strong></p>
          <ol>
            <li>
              Esta seção G define nossas políticas e procedimentos de retenção
              de dados, projetados para ajudar a garantir o cumprimento de
              nossas obrigações legais em relação à retenção e exclusão de
              informações pessoais.
            </li>
            <li>
              As informações pessoais que processamos para qualquer propósito ou
              propósitos não devem ser mantidas por mais tempo do que o
              necessário para esse propósito ou propósitos.
            </li>

            <li>
              Não obstante as outras disposições desta seção G, reteremos
              documentos (incluindo documentos eletrônicos) que contenham dados
              pessoais:
              <ol type="a">
                <li>na medida em que somos obrigados a fazê-lo por lei;</li>
                <li>
                  se acreditarmos que os documentos podem ser relevantes para
                  qualquer processo judicial em andamento ou potencial; e
                </li>
                <li>
                  para estabelecer, exercer ou defender nossos direitos legais
                  (incluindo fornecer informações a terceiros para fins de
                  prevenção de fraudes e redução do risco de crédito).
                </li>
              </ol>
            </li>
          </ol>
          <p><strong>H. Segurança de suas informações pessoais</strong></p>
          <ol>
            <li>
              Tomaremos as devidas precauções técnicas e organizacionais para
              evitar a perda, mau uso ou alteração de suas informações pessoais.
            </li>
            <li>
              Armazenaremos todas as suas informações pessoais fornecidas em
              nossos servidores seguros (protegidos por senha e firewall).
            </li>
            <li>
              Todas as transações financeiras eletrônicas realizadas através do
              nosso site serão protegidas por tecnologia de criptografia.
            </li>
            <li>
              Você reconhece que a transmissão de informações pela internet é
              inerentemente insegura e que não podemos garantir a segurança dos
              dados enviados pela internet.
            </li>
            <li>
              Você é responsável por manter em sigilo a senha usada para acessar
              nosso site; não solicitaremos sua senha (exceto quando você fizer
              login em nosso site).
            </li>
          </ol>
          <p><strong>I. Alterações</strong></p>
          <p>
            Podemos atualizar esta política periodicamente, através da
            publicação de uma nova versão em nosso site. Você deve verificar
            esta página ocasionalmente para garantir que compreende quaisquer
            alterações nesta política. Podemos notificá-lo sobre alterações
            nesta política por e-mail ou através do sistema de mensagens
            privadas em nosso site.
          </p>
          <p><strong>J. Seus direitos</strong></p>
          <p>
            Você pode nos instruir a fornecer qualquer informação pessoal que
            detenhamos sobre você; o fornecimento dessas informações estará
            sujeito ao seguinte:
          </p>
          <ol>
            <li>
              Fornecimento de evidência apropriada de sua identidade para esse
              objetivo.
            </li>
          </ol>
          <p>
            Podemos reter as informações pessoais solicitadas na extensão
            permitida por lei.
          </p>
          <p>
            Você pode nos instruir a qualquer momento para não processar suas
            informações pessoais para fins de marketing.
          </p>
          <p>
            Na prática, você geralmente concordará expressamente com
            antecedência com o uso de suas informações pessoais para fins de
            marketing, ou ofereceremos a oportunidade de não permitir o uso de
            suas informações pessoais para fins de marketing.
          </p>
          <p><strong>K. Sites de terceiros</strong></p>
          <p>
            Nosso site inclui links para e detalhes de sites de terceiros. Não
            temos controle sobre e não somos responsáveis pelas políticas e
            práticas de privacidade de terceiros.
          </p>
          <p><strong>L. Atualização de informações</strong></p>
          <p>
            Informe-nos se as informações pessoais que mantemos sobre você
            precisam ser corrigidas ou atualizadas.
          </p>
          <p><strong>M. Cookies</strong></p>
          <p>
            Nosso site usa cookies. Um cookie é um arquivo que contém um
            identificador (uma sequência de letras e números) que é enviado por
            um servidor da web para um navegador e armazenado pelo navegador. O
            identificador é então enviado de volta ao servidor toda vez que o
            navegador solicita uma página do servidor. Os cookies podem ser
            cookies “persistentes” ou cookies “de sessão”: um cookie persistente
            será armazenado por um navegador e permanecerá válido até a data de
            vencimento definida, a menos que seja excluído pelo usuário antes da
            data de vencimento; um cookie de sessão, por outro lado, expirará no
            final da sessão do usuário, quando o navegador for fechado. Os
            cookies normalmente não contêm nenhuma informação que identifique
            pessoalmente um usuário, mas as informações pessoais que armazenamos
            sobre você podem estar vinculadas às informações armazenadas e
            obtidas a partir de cookies. Utilizamos apenas cookies de sessão em
            nosso site.
          </p>
          <ol>
            <li>
              Os nomes dos cookies que usamos em nosso site e os propósitos para
              os quais são usados estão descritos abaixo:
              <ol type="a">
                <li>
                  usamos o Google Analytics e o Adwords em nosso site para
                  reconhecer um computador quando um usuário acessa o site e
                  rastrear usuários enquanto eles navegam no site.
                </li>
              </ol>
            </li>
            <li>
              A maioria dos navegadores permite que você se recuse a aceitar
              cookies – por exemplo:
              <ol type="a">
                <li>
                  no Internet Explorer (versão 10), você pode bloquear cookies
                  usando as configurações disponíveis de cancelamento de
                  manipulação de cookies clicando em “Ferramentas”, “Opções da
                  Internet” “Privacidade” e “Avançado”;
                </li>
                <li>
                  no Firefox (versão 24), você pode bloquear todos os cookies
                  clicando em “Ferramentas”, “Opções”, “Privacidade”,
                  selecionando “Usar configurações personalizadas para o
                  histórico” no menu suspenso e desmarcando “Aceitar cookies de
                  sites” ; e
                </li>
                <li>
                  no Chrome (versão 29), você pode bloquear todos os cookies
                  acessando o menu “Personalizar e controlar” e clicando em
                  “Configurações”, “Avançado” e “Configurações do site” e, em
                  seguida, selecionando “Bloquear cookies de terceiros” na seção
                  “Cookies e dados do site”.
                </li>
              </ol>
            </li>
          </ol>
          <p>
            O bloqueio de todos os cookies causará um impacto negativo na
            usabilidade de muitos sites. Se você bloquear os cookies, não poderá
            usar todos os recursos em nosso site.
          </p>
          <ol start="3">
            <li>
              Você pode excluir os cookies que já estão armazenados no seu
              computador – por exemplo:
              <ol type="a">
                <li>
                  no Internet Explorer (versão 10), você deve excluir os
                  arquivos de cookies manualmente (confira instruções para
                  fazê-lo em
                  <a href="http://support.microsoft.com/kb/278835"
                    >http://support.microsoft.com/kb/278835</a
                  >
                  );
                </li>
                <li>
                  no Firefox (versão 24), você pode excluir os cookies clicando
                  em “Ferramentas”, “Opções”, “Privacidade”, selecionando “Usar
                  configurações personalizadas para o histórico”, clicando em
                  “Mostrar cookies” e, então, em “Remover todos os cookies”; e
                </li>
                <li>
                  no Chrome (versão 29), você pode excluir todos os cookies
                  acessando o menu “Personalizar e controlar” e clicando em
                  “Configurações”, “Avançado”, “Limpar dados de navegação” e, em
                  seguida, selecionando “Excluir cookies e outros dados de sites
                  e plugins” antes de clicar em “Limpar dados de navegação”.
                </li>
              </ol>
            </li>
            <li>
              A exclusão de cookies causará um impacto negativo na usabilidade
              de muitos sites.
            </li>
          </ol>
          <p>
            <em
              >O site Lyne não assume qualquer responsabilidade e aconselha você
              a consultar especialistas jurídicos se estiver implementando o
              modelo acima em seu site.</em
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  head() {
    return {
      title: {
        inner: 'Home'
      },
      meta: [
        {
          name: 'description',
          content: `${this.appTitle} home page`,
          id: 'desc'
        }
      ]
    }
  },
  computed: mapState('app', ['appTitle'])
}
</script>
